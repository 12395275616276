import axios from "axios";

const state = {
    lightboxImage: '',
    lightboxVideo: '',
    lightboxConfidential: '',
};

const getters = {};

const actions = {};

const mutations = {
    UPDATE_LIGHTBOX_IMAGE_FIELD_SERVICE(state, data) {      
        state.lightboxImage = data;
    },
    CLEAR_LIGHTBOX_IMAGE_FIELD_SERVICE(state, data) {      
        state.lightboxImage = '';
    },
    UPDATE_LIGHTBOX_VIDEO_FIELD_SERVICE(state, data) {      
        state.lightboxVideo = data;
    },
    CLEAR_LIGHTBOX_VIDEO_FIELD_SERVICE(state, data) {      
        state.lightboxVideo = '';
    },
    UPDATE_LIGHTBOX_CONF_FIELD_SERVICE(state, data) {      
        state.lightboxConfidential = data;
    },
    CLEAR_LIGHTBOX_CONF_FIELD_SERVICE(state, data) {      
        state.lightboxConfidential = '';
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}