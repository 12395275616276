import axios from "axios";

const state = {
    announcementHomePosts: [],
};

const getters = {
    getHomeAnnouncements: (state) => {
        return state.announcementHomePosts;
    }
};

const actions = {};

const mutations = {
    SET_ANNOUNCEMENTS_HOME(state) {
        let axiosConfig = {
            headers: {
                Authorization: "Bearer " + state.token,
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        };
        return axios
            .get(
                `https://spacenetcontent.spacesaver.com/wp-json/wp/v2/news?per_page=30`
            )
            .then((response) => {
                state.announcementHomePosts = response.data;
            });
    },
    UPDATE_ANNOUNCEMENTS_HOME(state, data) {      
        state.announcementHomePosts = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}