import axios from "axios";

const state = {
    faqHomePosts: [],
    faqAllPosts: [],
};

const getters = {
    getFAQHomePosts: (state) => {
        return state.faqHomePosts;
    },
    getFAQAllPosts: (state) => {
        return state.faqAllPosts;
    }
};

const actions = {};

const mutations = {
    SET_FAQS_HOME(state) {
        return axios
            .get(
                `https://spacenetcontent.spacesaver.com/wp-json/wp/v2/spacenet_faqs?per_page=6`
            )
            .then((response) => {
                //console.log(response);
                state.faqHomePosts = response.data;
            });
    },
    SET_FAQS_ALL(state) {
        return axios
            .get(
                `https://spacenetcontent.spacesaver.com/wp-json/wp/v2/spacenet_faqs`
            )
            .then((response) => {
                //console.log(response);
                state.faqAllPosts = response.data;
            });
    },
    UPDATE_FAQS_HOME(state, data) {      
        state.faqHomePosts = data;
    },
    UPDATE_FAQS_ALL(state, data) {      
        state.faqAllPosts = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}