import axios from "axios";

const state = {
    trailblazor: process.env.VUE_APP_TRAILBLAZOR,
    trailblazorPosts: [],
};

const getters = {
    getTrailblazors: (state) => {
        return state.trailblazorPosts;
    },
};

const actions = {};

const mutations = {
    SET_TRAILBLAZOR(state) {
        return axios
            .get(`https://mktginfo.spacesaver.com/wp-json/wp/v2/posts/?per_page=100`)
            .then((response) => {
                state.trailblazorPosts = response.data;
            });
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}