import axios from "axios";

const state = {
    reportList: {},
    ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
    token: localStorage.getItem("SSCUSER"),
};

const getters = {
    getreportList: (state) => {
        return state.reportList;
    }
};

const actions = {};

const mutations = {
    SET_REPORTS(state) {
        let axiosConfig = {
            headers: {
                Authorization: "Bearer " + state.token,
            },
        };
        //console.log("started getting reports from Vuex");
        return axios
            .get(`https://ordermanage.spacesaver.com/reports`, axiosConfig)
            .then((response) => {
                //console.log(response);
                //console.log("finished Vuex reports " + response);
                state.reportList = response.data;
            });
    },
    UPDATE_REPORTS(state, data) {      
        state.reportList = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}