import axios from "axios";

const state = {
    listings: [],
    ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
    token: localStorage.getItem("SSCUSER"),
};

const getters = {
    getlistingList: (state) => {
        return state.listings;
    }
};

const actions = {};

const mutations = {
    SET_LISTINGS(state) {
        let axiosConfig = {
            headers: {
                Authorization: "Bearer " + state.token,
            },
        };
        //console.log("started Vuex listings");
        return axios
            .get(`${this.ordermanageapi}/order`, axiosConfig)
            .then((response) => {
                //fix null estinated dates so sorting will work
                response.data.forEach(function (o) {
                    if (o.estimated_Date === null) {
                        o.estimated_Date = "";
                    }
                });
                //console.log(response);
                //console.log("finished Vuex listings " + response);
                state.listings = response.data;
            });
    },
    UPDATE_LISTINGS(state, data) {
        state.listings = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}