<template> 
<div> 
<header v-if="navigationState == true" style="
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
    height: 70px;
">
    <MainNavbarDEV v-cloak></MainNavbarDEV>
</header>
<div class="pages-inner">
        <transition
          name="fade"
          mode="out-in"
          @beforeLeave="beforeLeave"
          @enter="enter"
          @afterEnter="afterEnter"
        >
          <router-view />
        </transition>
</div>
<footer-old/>
</div>
</template>
<script>
//import MainNavbar from "@/components/MainNavbar";
import MainNavbarDEV from "@/components/MainNavbarDEV";
import FooterOld from '../Footer/FooterOld.vue';

export default {
  name: "WebLayout",
  components: {
      //MainNavbar,
      MainNavbarDEV,
      FooterOld
  },
  props: [],
  data() {
    return {
     
    };
  },
  methods: {
     beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
  },
  computed: {
    navigationState() {
      return this.$store.state.nav;
    },
    colorGetter() {
    return this.$store.state.colors;
    },  
     getyear() {
      return new Date().getFullYear();
    },
  }
}
</script>
<style scoped>

</style>
