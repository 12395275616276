import axios from "axios";

const state = {
    commercialParts: null,
    serviceParts: null,
    ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
    token: localStorage.getItem("SSCUSER"),
};

const getters = {
    getCommercial: (state) => {
        return state.commercial;
    },
    getService: (state) => {
        return state.service;
    }
};

const actions = {};

const mutations = {

    SET_COMMERCIAL(state) {
        let axiosConfig = {
            headers: {
                Authorization: "Bearer " + state.token,
            },
        };
        //console.log("started getting commercial price list from Vuex");
        return axios
            .get(process.env.VUE_APP_ORDER_MANAGE_API  + '/pricelist?type=COMMERCIAL', axiosConfig)
            .then((response) => {
                //console.log(response);
                //console.log("finished Commercial ");
                //console.log(response.data);
                state.commercialParts = response.data.prices;
            });
    },

    SET_SERVICE(state) {
        let axiosConfig = {
            headers: {
                Authorization: "Bearer " + state.token,
            },
        };
        console.log("started getting service price list from Vuex");
        return axios
            .get(process.env.VUE_APP_ORDER_MANAGE_API + '/pricelist?type=SERVICE', axiosConfig)
            .then((response) => {
                //console.log(response);
                //console.log("finished Service ");
                //console.log(response.data);
                state.serviceParts = response.data.prices;
            });
    },
    UPDATE_COMMERCIAL(state, data) {      
        state.commercialParts = data;
    },
    UPDATE_SERVICE(state, data) {      
        state.serviceParts = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}