import axios from "axios";

const state = {
    questions: [],
    questionsHome: [],
};

const getters = {};

const actions = {};

const mutations = {

    SET_COLLABORATE(state) {
        let axiosConfig = {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
            },
        };
        return axios
            .get(
                `${process.env.VUE_APP_SERVER_URL}/api/v1/questions`, axiosConfig
            )
            .then((response) => {
                state.questions = response.data;
            });
    },
    UPDATE_COLLABORATE(state, data) {
        state.questions = data;
    },
    UPDATE_COLLABORATE_HOME(state, data) {
        state.questionsHome = data;
    },

};

export default {
    state,
    getters,
    actions,
    mutations
}