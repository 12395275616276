import axios from "axios";

const state = {
    cashManageList: {},
    ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
    token: localStorage.getItem("SSCUSER"),
};

const getters = {
    getCashManageList: (state) => {
        return state.cashManageList;
    }
};

const actions = {};

const mutations = {
    SET_CASH(state) {
        let axiosConfig = {
            headers: {
                Authorization: "Bearer " + state.token,
            },
        };
        //console.log("started getting cash Manage from Vuex");
        return axios
            .get(`${this.ordermanageapi}/cashmanagement/`, axiosConfig)
            .then((response) => {
                //console.log(response);
                //console.log("finished Vuex cash manage " + response);
                state.cashManageList = response.data.aging;
            });
    },
    UPDATE_CASH(state, data) {      
        state.cashManageList = data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}